import React from 'react'
import Seo from '../components/SEO'
import { useStaticQuery, graphql } from 'gatsby'

import { Link } from 'gatsby'
import Img from 'gatsby-image'
import * as s from '../styles/pages/splash.scss'

const SPLASH_PAGE_QUERY = graphql`
    query {
        prismicSplashPage {
            data {
                splash_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                intro_content {
                    html
                }
            }
        }
    }
`

const IndexPage = () => {
    const { prismicSplashPage } = useStaticQuery(SPLASH_PAGE_QUERY)

    return (
        <>
            <Seo title="Home" />
            <section className={s.section}>
                <div className={s.section__wrapper}>
                    <div className={s.section__img}>
                        <Img
                            fluid={
                                prismicSplashPage.data.splash_image.localFile
                                    .childImageSharp.fluid
                            }
                            className={s.imageWrapper}
                        />
                    </div>
                    <div className={s.section__body}>
                        {/* {RichText.render(splash_page.intro_content)} */}
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    prismicSplashPage.data.intro_content.html,
                            }}
                        ></div>
                        <div className={s.buttons}>
                            <Link to="/lincoln" className={`btn ${s.btn}`}>
                                Lincoln
                            </Link>
                            <Link to="/omaha" className={`btn ${s.btn}`}>
                                Omaha
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndexPage
